@keyframes PROCESSING-ANIMATION {
  0% {
    transform: translateX(0)
  }
  to {
    transform: translateX(46px)
  }
}

@keyframes PAUSE-BLINK {
  0%, to {
    opacity: 1
  }
  50% {
    opacity: .2
  }
}

@keyframes TIME-BLINK {
  0%, 80%, to {
    color: #dc112e
  }
}

@keyframes dots {
  0%, 20% {
    color: transparent;
    text-shadow: .25em 0 0 transparent, .5em 0 0 transparent
  }
  40% {
    color: #fff;
    text-shadow: .25em 0 0 transparent, .5em 0 0 transparent
  }
  60% {
    text-shadow: .25em 0 0 #fff, .5em 0 0 transparent
  }
  80%, to {
    text-shadow: .25em 0 0 #fff, .5em 0 0 #fff
  }
}

.wc-video-recorder-container {
  position :absolute;
  width: 70%;
  margin: 0;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: "Open Sans", sans-serif;
  text-align: left
}

.video-recorder {
  width: 100%;
  position: relative;
  background-color: transparent;
  -webkit-user-select: none;
  -khtml-user-drag: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0;
}

.video-recorder .video-output {
  transition: transform .2s
}

.video-recorder ::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

.video-recorder .relative {
  position: relative
}

.video-recorder button {
  background: 0 0;
  background-image: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  padding: 0
}

.video-recorder .flex-1 {
  display: flex;
  align-items: center;
  flex: 1
}

.video-recorder .flex-left {
  justify-content: flex-start
}

.video-recorder .flex-right {
  justify-content: flex-end
}

.video-recorder .flex-center {
  justify-content: center
}

.video-recorder .hidden {
  display: none;
}

.video-recorder .video-container canvas, .video-recorder .video-container video, .video-recorder .video-player-container canvas, .video-recorder .video-player-container video {
  width: 100%
}

.video-recorder:-webkit-full-screen video {
  width: 100vw;
  height: 100vh;
  object-fit: contain
}

.video-recorder:-moz-full-screen video {
  width: 100vw;
  height: 100vh;
  object-fit: contain
}

.video-recorder:fullscreen video {
  width: 100vw;
  height: 100vh;
  object-fit: contain
}

.video-recorder .bottom-menu, .video-recorder .record-timer {
  position: absolute;
  opacity: 1;
  display: flex;
  transition: opacity .2s
}

.video-recorder .record-timer {
  left: 50%;
  top: 10px;
  z-index: 50;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  background-color: rgba(46, 49, 54, .9);
  border-radius: 35px;
  transform: translate(-50%, 0);
  padding: 4px 7px;
  line-height: 1;
  color: #848484
}

.video-recorder .record-timer .icon {
  width: 0;
  height: 0;
  box-sizing: border-box;
  visibility: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg width='8px' height='8px' viewBox='0 0 8 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Ccircle id='Rec' fill='%23DD0B36' cx='4' cy='4' r='4'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
  transition: all .2s
}

.video-recorder .record-timer .time-total {
  font-weight: 600
}

.video-recorder .bottom-menu .settings .dialog-settings .settings-group ul li:hover, .video-recorder .record-timer .time-elapsed {
  color: #fff
}

.video-recorder .record-timer .time-elapsed.time-blink {
  color: #dc112e
}

.video-recorder .bottom-menu {
  left: 0;
  right: 0;
  height: 65px;
  bottom: 0;
  justify-content: space-between;
  font-size: 0;
  background: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, .5) 100%)
}

.video-recorder .bottom-menu .btn-photo {
  padding: 0;
  font-size: 0;
  margin-left: 10px;
  margin-top: 2px;
  cursor: pointer;
  opacity: 1;
  transition: all .2s;
  border-radius: 50%;
  background-color: rgba(46, 49, 54, .6);
  width: 46px;
  height: 46px
}

.video-recorder .bottom-menu .btn-photo #cameraIcon {
  transform-origin: center center;
  transition: transform .2s
}

.video-recorder .bottom-menu .btn-photo:hover, .video-recorder .bottom-menu .btn-record:hover {
  background-color: #2e3136
}

.video-recorder .bottom-menu .btn-photo:hover #cameraIcon, .video-recorder .bottom-menu .btn-record:hover {
  transform: scale(1.03, 1.03)
}

.video-recorder .bottom-menu .btn-record {
  padding: 0;
  font-size: 0;
  margin-left: 10px;
  margin-top: 2px;
  cursor: pointer;
  opacity: 1;
  transition: all .2s;
  border-radius: 50%;
  background-color: rgba(46, 49, 54, .6);
  width: 46px;
  height: 46px
}

.video-recorder .bottom-menu .btn-record .btn-record-timer {
  opacity: 1
}

.video-recorder .bottom-menu .btn-record.active {
  border-radius: 22.5px
}

.video-recorder .bottom-menu .btn-record.active .icn-record {
  border-radius: 5px;
  width: 20px;
  height: 20px
}

.video-recorder .bottom-menu .btn-pause-record:hover #ui_kit, .video-recorder .bottom-menu .btn-record.active .record-timer {
  opacity: 1
}

.video-recorder .bottom-menu .btn-record.active ~ .btn-pause-record {
  visibility: visible;
  width: 32px;
  height: 32px;
  left: calc(50% + 32px);
  top: 50%;
  transform: translateY(-50%);
  opacity: 1
}

.video-recorder .bottom-menu .btn-pause-record {
  border-radius: 50%;
  width: 0;
  height: 0;
  background-color: #2e3136;
  box-sizing: border-box;
  transition: all .2s;
  font-size: 0;
  margin-left: 10px;
  opacity: 0;
  visibility: hidden;
  position: absolute
}

.video-recorder .bottom-menu .btn-pause-record #ui_kit {
  transition: all .2s;
  opacity: .7
}

.video-recorder .bottom-menu .settings {
  position: relative
}

.video-recorder .bottom-menu .settings .dialog-settings {
  position: absolute;
  bottom: 34px;
  right: -23px;
  opacity: .97;
  border-radius: 8px;
  background-color: #272a30;
  min-width: 210px;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  font-weight: 600
}

.video-recorder .bottom-menu .settings .dialog-settings .settings-group {
  padding: 13px 0 15px;
  border-bottom: solid 1px #32343a
}

.video-recorder .bottom-menu .settings .dialog-settings .settings-group .settings-header {
  padding-left: 32px;
  padding-right: 37px
}

.video-recorder .bottom-menu .settings .dialog-settings .settings-group .settings-header.wrapper {
  padding-right: 21px
}

.video-recorder .bottom-menu .settings .dialog-settings .settings-group ul {
  margin: 0;
  padding: 10px 0 0;
  list-style: none
}

.video-recorder .bottom-menu .settings .dialog-settings .settings-group ul li {
  padding-left: 32px;
  padding-right: 37px;
  padding-bottom: 10px;
  color: #d7dce6;
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap
}

.video-recorder .bottom-menu .settings .dialog-settings .settings-group ul li .a-indicator {
  vertical-align: middle;
  display: inline-block;
  visibility: hidden;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #00ff8e
}

.video-recorder .bottom-menu .settings .dialog-settings .settings-group ul li span {
  vertical-align: middle;
  display: inline-block;
  margin-left: 10px
}

.video-recorder .bottom-menu .settings .dialog-settings .settings-group ul li.active .a-indicator {
  visibility: visible
}

.video-recorder .bottom-menu .settings .dialog-settings .settings-group ul li:last-child {
  padding-bottom: 0
}

.video-recorder .bottom-menu .settings .dialog-settings .settings-group:last-child {
  border-bottom: none
}

.video-recorder .bottom-menu .settings .dialog-settings .mic-enable .wrapper, .video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.video-recorder .bottom-menu .settings .dialog-settings .mic-enable .wrapper .mic-check, .video-recorder .bottom-menu .settings .dialog-settings .mic-enable .wrapper .mirror-check, .video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .wrapper .mic-check, .video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .wrapper .mirror-check {
  width: 30px;
  font-size: 0;
  background-color: #4b4d52;
  border-radius: 15px;
  position: relative;
  height: 15px;
  cursor: pointer
}

.video-recorder .bottom-menu .settings .dialog-settings .mic-enable .wrapper .mic-check .mic-check-icon, .video-recorder .bottom-menu .settings .dialog-settings .mic-enable .wrapper .mic-check .mirror-check-icon, .video-recorder .bottom-menu .settings .dialog-settings .mic-enable .wrapper .mirror-check .mic-check-icon, .video-recorder .bottom-menu .settings .dialog-settings .mic-enable .wrapper .mirror-check .mirror-check-icon, .video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .wrapper .mic-check .mic-check-icon, .video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .wrapper .mic-check .mirror-check-icon, .video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .wrapper .mirror-check .mic-check-icon, .video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .wrapper .mirror-check .mirror-check-icon {
  transition: all .2s;
  display: inline-block;
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  right: 50%
}

.video-recorder .bottom-menu .settings .dialog-settings .mic-enable .select-mic, .video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .select-mic {
  margin: 0;
  list-style: none
}

.video-recorder .bottom-menu .settings .dialog-settings .mic-enable .settings-mics, .video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .settings-mics {
  overflow: hidden;
  transition: all 300ms ease-in-out;
  max-height: 0
}

.video-recorder .bottom-menu .settings .dialog-settings .mic-enable .settings-mics.visible, .video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .settings-mics.visible, .video-recorder .bottom-menu .settings .dialog-settings .settings-camera.visible {
  display: block
}

.video-recorder .bottom-menu .settings .dialog-settings .mic-enable.active .settings-mics, .video-recorder .bottom-menu .settings .dialog-settings .mirror-enable.active .settings-mics {
  max-height: 400px;
  transition-delay: .2s
}

.video-recorder .bottom-menu .settings .dialog-settings .mic-enable.active .wrapper .mic-check .mic-check-icon, .video-recorder .bottom-menu .settings .dialog-settings .mic-enable.active .wrapper .mic-check .mirror-check-icon, .video-recorder .bottom-menu .settings .dialog-settings .mic-enable.active .wrapper .mirror-check .mic-check-icon, .video-recorder .bottom-menu .settings .dialog-settings .mic-enable.active .wrapper .mirror-check .mirror-check-icon, .video-recorder .bottom-menu .settings .dialog-settings .mirror-enable.active .wrapper .mic-check .mic-check-icon, .video-recorder .bottom-menu .settings .dialog-settings .mirror-enable.active .wrapper .mic-check .mirror-check-icon, .video-recorder .bottom-menu .settings .dialog-settings .mirror-enable.active .wrapper .mirror-check .mic-check-icon, .video-recorder .bottom-menu .settings .dialog-settings .mirror-enable.active .wrapper .mirror-check .mirror-check-icon {
  right: 0;
  background-color: #00ff8e
}

.video-recorder .bottom-menu .settings .dialog-settings .settings-camera {
  display: none
}

.video-recorder .bottom-menu .settings .btn-settings {
  margin-right: 13px;
  margin-top: 11px;
  opacity: .95
}

.video-recorder .bottom-menu .settings .btn-settings path {
  transition: transform .2s;
  transform-origin: center center
}

.video-recorder .bottom-menu .btn-fs:hover, .video-recorder .bottom-menu .settings .btn-settings:hover {
  opacity: 1
}

.video-recorder .bottom-menu .settings .btn-settings:hover path {
  transform-origin: center center;
  transform: rotate(30deg)
}

.video-recorder .bottom-menu .btn-fs {
  margin-right: 15px;
  margin-top: 11px;
  opacity: .95
}

.video-recorder .alert .btn-fs #fullscreen, .video-recorder .bottom-menu .btn-fs #fullscreen, .video-recorder .photo-preview .btn-fs #fullscreen, .video-recorder .video-player-container .btn-fs #fullscreen {
  transition: all .2s;
  transform-origin: center center
}

.video-recorder .bottom-menu .btn-fs:hover #fullscreen {
  transform: scale(1.1, 1.1)
}

.video-recorder:-webkit-full-screen .btn-fs:hover #fullscreen {
  transform: scale(.9, .9);
}

.video-recorder:-moz-full-screen .btn-fs:hover #fullscreen {
  transform: scale(.9, .9);
}

.video-recorder:fullscreen .btn-fs:hover #fullscreen {
  transform: scale(.9, .9);
}

.video-recorder .processing-info {
  transition: all .2s;
  height: 46px;
  max-width: 0;
  border-radius: 22.5px;
  position: relative;
  overflow: hidden
}

.video-recorder .processing-info .processing-text {
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  line-height: 46px;
  position: relative;
  height: 46px
}

.video-recorder .processing-info .processing-text .the-text {
  opacity: .6;
  padding-left: 22px;
  padding-right: 5px;
  float: left;
  position: relative
}

.video-recorder .processing-info .processing-text .processing-progress {
  opacity: .6;
  float: left;
  position: relative
}

.video-recorder .processing-info .btn-cancel-proccessing {
  margin-right: 10px;
  margin-top: 9px;
  opacity: .7;
  transition: opacity 150ms ease-in-out;
  position: relative;
  float: left
}

.video-recorder .alert .btn-close:hover svg, .video-recorder .photo-preview .btn-close:hover svg, .video-recorder .processing-info .btn-cancel-proccessing:hover, .video-recorder .video-player-container .btn-close:hover svg, .video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .btn-play-video:hover .arrow, .video-recorder .video-player-container .video-player-wrapper.play-mode .video-player-controls .wrap .video-controls .btn-play-video:hover .arrow {
  opacity: 1
}

.video-recorder .processing-info .btn-cancel-proccessing:after {
  float: none
}

.video-recorder .processing-info .processing-info-inner {
  position: absolute;
  left: -46px;
  right: 0;
  top: 0;
  bottom: 0;
  background: repeating-linear-gradient(-65deg, #2e3136, #2e3136 10px, #34373c 10px, #34373c 20px);
  animation-name: PROCESSING-ANIMATION;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite
}

.video-recorder .video-player-container {
  display: none;
  background-color: #272a30;
  position: relative;
  overflow: hidden;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1
}

.video-recorder .video-player-container .close-btn {
  position: absolute;
  top: 5px;
  right: 5px
}

.video-recorder .video-player-container .video-player-wrapper {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 5s
}

.video-recorder .video-player-container .video-player-wrapper:-webkit-full-screen .btn-fs {
  opacity: .95
}

.video-recorder .video-player-container .video-player-wrapper:-webkit-full-screen .btn-fs polygon {
  transform-origin: center;
  transform: scale(-1, -1)
}

.video-recorder .video-player-container .video-player-wrapper:-webkit-full-screen .btn-fs:hover {
  opacity: 1
}

.video-recorder .video-player-container .video-player-wrapper:-moz-full-screen .btn-fs {
  opacity: .95
}

.video-recorder .video-player-container .video-player-wrapper:-moz-full-screen .btn-fs polygon {
  transform-origin: center;
  transform: scale(-1, -1)
}

.video-recorder .video-player-container .video-player-wrapper:-moz-full-screen .btn-fs:hover {
  opacity: 1
}

.video-recorder .video-player-container .video-player-wrapper:fullscreen .btn-fs {
  opacity: .95
}

.video-recorder .video-player-container .video-player-wrapper:fullscreen .btn-fs polygon {
  transform-origin: center;
  transform: scale(-1, -1)
}

.video-recorder .video-player-container .video-player-wrapper:fullscreen .btn-fs:hover {
  opacity: 1
}

.video-recorder .video-player-container .video-player-wrapper .video-player-controls {
  position: absolute;
  bottom: 10px;
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: stretch
}

.video-recorder .photo-preview .photo-preview-controls .wrap, .video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap {
  display: flex;
  height: 100%;
  flex: 1;
  align-items: center;
  justify-content: center
}

.video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls {
  max-width: 50%;
  height: 100%;
  border-radius: 22.5px;
  background-color: rgba(46, 49, 54, .95);
  transition: all .2s;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: stretch
}

.video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .btn-play-video {
  margin-left: 25px;
  margin-right: 20px;
  width: 12px
}

.video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .btn-play-video .arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 12px solid #fff;
  transition: border-top .2s, border-bottom .2s, border-left .2s, border-right .2s, opacity 150ms ease-in-out;
  opacity: .7
}

.video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .control-seek-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  flex: 1;
  position: relative;
  cursor: pointer
}

.video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .control-seek-bar .control-seek-bar-back {
  width: 100%;
  height: 18px
}

.video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .control-seek-bar .control-seek-bar-back-inner {
  height: 4px;
  width: 100%;
  top: 50%;
  position: absolute;
  transform: translate(0, -50%);
  background-color: #575a5e;
  border-radius: 26px
}

.video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .control-seek-bar .control-seek-bar-stripe {
  height: 4px;
  top: 50%;
  position: absolute;
  transform: translate(0, -50%);
  border-radius: 26px;
  width: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
  background-color: #fff
}

.video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .control-seek-bar .control-seek-bar-dot {
  z-index: 1;
  background-color: #fff;
  border-radius: 50%;
  left: 0;
  transform: translate(-50%, 0);
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  transition: box-shadow .2s;
  box-shadow: none
}

.video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .control-seek-bar .control-seek-bar-dot:active, .video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .control-seek-bar .control-seek-bar-dot:hover {
  box-shadow: 0 0 15px rgba(255, 255, 255, .5)
}

.video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .out-current-time, .video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .out-total-time {
  font-size: 12px;
  color: #fff;
  font-weight: 300;
  opacity: .5
}

.video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .out-total-time {
  margin-right: 25px
}

.video-recorder .video-player-container .video-player-wrapper .video-player-controls .btn-fs {
  margin-right: 20px
}

.video-recorder .video-player-container .video-player-wrapper.play-mode .video-player-controls .wrap .video-controls .btn-play-video .arrow {
  width: 12px;
  height: 15px;
  border-left: solid 4px #fff;
  border-right: solid 4px #fff;
  border-top: solid 0 transparent;
  border-bottom: solid 0 transparent;
  box-sizing: border-box;
  opacity: .7
}

.video-recorder .photo-preview {
  display: none;
  background-color: #272a30;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  text-align: center
}

.video-recorder .photo-preview .img {
  width: 100%
}

.video-recorder .photo-preview .photo-preview-controls {
  position: absolute;
  bottom: 10px;
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: stretch
}

.video-recorder .photo-preview .photo-preview-controls .btn-fs {
  margin-right: 10px;
  margin-top: 11px
}

.video-recorder:-webkit-full-screen .img {
  width: 100vw;
  height: 100vh;
  object-fit: contain
}

.video-recorder:-moz-full-screen .img {
  width: 100vw;
  height: 100vh;
  object-fit: contain
}

.video-recorder:fullscreen .img {
  width: 100vw;
  height: 100vh;
  object-fit: contain
}

.video-recorder.active .btn-photo {
  opacity: 0
}

.video-recorder.active .btn-settings {
  visibility: hidden
}

.video-recorder.active .record-timer .icon {
  visibility: visible;
  margin-right: 4px;
  width: 8px;
  height: 8px
}

.video-recorder.active .record-timer .time-elapsed {
  font-weight: 600
}

.video-recorder.initializing .bottom-menu, .video-recorder.initializing .record-timer {
  opacity: 0
}

.video-recorder.paused .record-timer .icon {
  visibility: visible;
  background-image: url("data:image/svg+xml,%3Csvg width='6px' height='8px' viewBox='0 0 6 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Pause' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Crect id='Rectangle-22' x='0' y='0' width='2' height='8' rx='1'%3E%3C/rect%3E%3Crect id='Rectangle-22' x='4' y='0' width='2' height='8' rx='1'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
}

.video-recorder.paused .btn-record .record-timer {
  color: #fff
}

.video-recorder.paused .btn-pause-record #ui_kit {
  animation-name: PAUSE-BLINK;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite
}

.video-recorder.processing .processing-info {
  display: inline-block;
  max-width: 300px
}

.video-recorder.processing .record-timer {
  opacity: 0
}

.video-recorder.processing .btn-record {
  max-width: 300px;
  background-color: transparent
}

.video-recorder.processing .btn-record .btn-record-timer {
  opacity: 0;
  width: 0
}

.video-recorder.cancel-processing .cancel-processing-confirmation {
  visibility: visible;
  z-index: 5;
  opacity: 1
}

.video-recorder.remove-video-confirmation {
  z-index: -11
}

.video-recorder.remove-video .video-player-controls {
  display: none;
}

.video-recorder.preview .video-player-container, .video-recorder.preview-photo .photo-preview {
  display: block
}

.video-recorder.preview .video-container {
  display: none
}

.video-recorder.preview .record-timer {
  opacity: 0
}

.video-recorder.preview .processing-info {
  display: none
}

.video-recorder.browser-not-supported .browser-not-supported {
  visibility: visible;
  z-index: 5;
  opacity: 1
}

.video-recorder .video-container,.video-recorder .video-player-container {
  min-height: 480px;
}
.video-recorder .video-container canvas,.video-recorder .video-container video,.video-recorder .video-player-container canvas,.video-recorder .video-player-container video {
  width: 100%;
}
.video-recorder .bottom-menu,.video-recorder .record-timer {
  position: absolute;
  opacity: 1;
  display: flex;
  transition: opacity .2s;
}
.video-recorder .bottom-menu .settings .dialog-settings .settings-group ul li:hover,.video-recorder .record-timer .time-elapsed {
  color: #fff;
}
.video-recorder .bottom-menu .btn-pause-record:hover #ui_kit,.video-recorder .bottom-menu .btn-record.active .record-timer {
  opacity: 1;
}
.video-recorder .bottom-menu .settings .dialog-settings .mic-enable .wrapper,.video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.video-recorder .bottom-menu .settings .dialog-settings .mic-enable .wrapper .mic-check,.video-recorder .bottom-menu .settings .dialog-settings .mic-enable .wrapper .mirror-check,.video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .wrapper .mic-check,.video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .wrapper .mirror-check {
  width: 30px;
  font-size: 0;
  background-color: #4b4d52;
  border-radius: 15px;
  position: relative;
  height: 15px;
  cursor: pointer;
}
.video-recorder .bottom-menu .settings .dialog-settings .mic-enable .wrapper .mic-check .mic-check-icon,.video-recorder .bottom-menu .settings .dialog-settings .mic-enable .wrapper .mic-check .mirror-check-icon,.video-recorder .bottom-menu .settings .dialog-settings .mic-enable .wrapper .mirror-check .mic-check-icon,.video-recorder .bottom-menu .settings .dialog-settings .mic-enable .wrapper .mirror-check .mirror-check-icon,.video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .wrapper .mic-check .mic-check-icon,.video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .wrapper .mic-check .mirror-check-icon,.video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .wrapper .mirror-check .mic-check-icon,.video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .wrapper .mirror-check .mirror-check-icon {
  transition: all .2s;
  display: inline-block;
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  right: 50%;
}
.video-recorder .bottom-menu .settings .dialog-settings .mic-enable .select-mic,.video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .select-mic {
  margin: 0;
  list-style: none;
}
.video-recorder .bottom-menu .settings .dialog-settings .mic-enable .settings-mics,.video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .settings-mics {
  overflow: hidden;
  transition: all 300ms ease-in-out;
  max-height: 0;
}
.video-recorder .bottom-menu .settings .dialog-settings .mic-enable .settings-mics.visible,.video-recorder .bottom-menu .settings .dialog-settings .mirror-enable .settings-mics.visible,.video-recorder .bottom-menu .settings .dialog-settings .settings-camera.visible {
  display: block;
}
.video-recorder .bottom-menu .settings .dialog-settings .mic-enable.active .settings-mics,.video-recorder .bottom-menu .settings .dialog-settings .mirror-enable.active .settings-mics {
  max-height: 400px;
  transition-delay: .2s;
}
.video-recorder .bottom-menu .settings .dialog-settings .mic-enable.active .wrapper .mic-check .mic-check-icon,.video-recorder .bottom-menu .settings .dialog-settings .mic-enable.active .wrapper .mic-check .mirror-check-icon,.video-recorder .bottom-menu .settings .dialog-settings .mic-enable.active .wrapper .mirror-check .mic-check-icon,.video-recorder .bottom-menu .settings .dialog-settings .mic-enable.active .wrapper .mirror-check .mirror-check-icon,.video-recorder .bottom-menu .settings .dialog-settings .mirror-enable.active .wrapper .mic-check .mic-check-icon,.video-recorder .bottom-menu .settings .dialog-settings .mirror-enable.active .wrapper .mic-check .mirror-check-icon,.video-recorder .bottom-menu .settings .dialog-settings .mirror-enable.active .wrapper .mirror-check .mic-check-icon,.video-recorder .bottom-menu .settings .dialog-settings .mirror-enable.active .wrapper .mirror-check .mirror-check-icon {
  right: 0;
  background-color: #00ff8e;
}
.video-recorder .bottom-menu .btn-fs:hover,.video-recorder .bottom-menu .settings .btn-settings:hover {
  opacity: 1;
}
.video-recorder .alert .btn-fs #fullscreen,.video-recorder .bottom-menu .btn-fs #fullscreen,.video-recorder .photo-preview .btn-fs #fullscreen,.video-recorder .video-player-container .btn-fs #fullscreen {
  transition: all .2s;
  transform-origin: center center;
}
.video-recorder .alert .btn-close:hover svg,.video-recorder .photo-preview .btn-close:hover svg,.video-recorder .processing-info .btn-cancel-proccessing:hover,.video-recorder .video-player-container .btn-close:hover svg,.video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .btn-play-video:hover .arrow,.video-recorder .video-player-container .video-player-wrapper.play-mode .video-player-controls .wrap .video-controls .btn-play-video:hover .arrow {
  opacity: 1;
}
.video-recorder .photo-preview .photo-preview-controls .wrap,.video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap {
  display: flex;
  height: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .control-seek-bar .control-seek-bar-dot:active,.video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .control-seek-bar .control-seek-bar-dot:hover {
  box-shadow: 0 0 15px rgba(255,255,255,.5);
}
.video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .out-current-time,.video-recorder .video-player-container .video-player-wrapper .video-player-controls .wrap .video-controls .out-total-time {
  font-size: 12px;
  color: #fff;
  font-weight: 300;
  opacity: .5;
}
.video-recorder .alert .btn-close,.video-recorder .photo-preview .btn-close,.video-recorder .video-player-container .btn-close {
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 20px;
  color: #fff;
  width: 46px;
  height: 46px;
  background-color: rgba(39,42,48,.8);
  border-radius: 50%;
  transition: all .2s;
}
.video-recorder .alert .btn-close svg,.video-recorder .photo-preview .btn-close svg,.video-recorder .video-player-container .btn-close svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  opacity: .9;
}
.video-recorder .alert .btn-close:hover,.video-recorder .photo-preview .btn-close:hover,.video-recorder .video-player-container .btn-close:hover {
  background-color: #272a30;
}
.video-recorder .alert .btn-save,.video-recorder .photo-preview .btn-save,.video-recorder .video-player-container .btn-save {
  font-size: 14px;
  background-color: rgba(255,255,255,.95);
  border-radius: 23px;
  height: 46px;
  line-height: 46px;
  margin: 0 10px;
  padding: 0 25px;
  color: #2e3136;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: color .2s,background-color .2s;
  position: relative;
}
.video-recorder .alert .btn-save.disabled,.video-recorder .photo-preview .btn-save.disabled,.video-recorder .video-player-container .btn-save.disabled {
  pointer-events: none!important;
  background-color: #e6e6e6;
  color: #9f9f9f!important;
}
.video-recorder .alert .btn-save.disabled path,.video-recorder .photo-preview .btn-save.disabled path,.video-recorder .video-player-container .btn-save.disabled path {
  fill: #9f9f9f!important;
}
.video-recorder .alert .btn-save span,.video-recorder .photo-preview .btn-save span,.video-recorder .video-player-container .btn-save span {
  margin-left: 10px;
}
.video-recorder .alert .btn-del path,.video-recorder .alert .btn-save path,.video-recorder .photo-preview .btn-del path,.video-recorder .photo-preview .btn-save path,.video-recorder .video-player-container .btn-del path,.video-recorder .video-player-container .btn-save path {
  transition: all .2s;
}
.video-recorder .alert .btn-save:hover,.video-recorder .photo-preview .btn-save:hover,.video-recorder .video-player-container .btn-save:hover {
  text-decoration: none;
  background-color: #fff;
  color: #19d481;
}
.video-recorder .alert .btn-save:hover path,.video-recorder .photo-preview .btn-save:hover path,.video-recorder .video-player-container .btn-save:hover path {
  fill: #19d481;
}
.video-recorder .alert .btn-del,.video-recorder .photo-preview .btn-del,.video-recorder .video-player-container .btn-del {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: rgba(46,49,54,.95);
  justify-content: center;
  border-radius: 50%;
  transition: background-color .2s;
}
.video-recorder .alert .btn-del:hover,.video-recorder .photo-preview .btn-del:hover,.video-recorder .video-player-container .btn-del:hover {
  text-decoration: none;
  background-color: #2e3136;
}
.video-recorder .alert .btn-del:hover path,.video-recorder .photo-preview .btn-del:hover path,.video-recorder .video-player-container .btn-del:hover path {
  fill: #f03;
}
.video-recorder .alert .btn-fs:hover,.video-recorder .photo-preview .btn-fs:hover,.video-recorder .video-player-container .btn-fs:hover {
  opacity: 1;
}
.video-recorder .alert .btn-fs:hover #fullscreen,.video-recorder .photo-preview .btn-fs:hover #fullscreen,.video-recorder .video-player-container .btn-fs:hover #fullscreen {
  transform: scale(1.1,1.1);
}
.video-recorder .dialog.cancel-processing-confirmation .btn-decline-cancel-req,.video-recorder .dialog.cancel-processing-confirmation .btn-decline-cancel-req:hover {
  text-decoration: none;
}
.video-recorder .alert .buttons .btn,.video-recorder .dialog .buttons .btn {
  display: inline-block;
  border-radius: 23px;
  padding: 12px 25px;
  color: #272a30;
  transition: all .2s;
  font-size: 14px;
  margin-right: 8px;
}
.video-recorder .alert .buttons .btn.white,.video-recorder .dialog .buttons .btn.white {
  background-color: rgba(255,255,255,.85);
}
.video-recorder .alert .buttons .btn.white:hover,.video-recorder .dialog .buttons .btn.white:hover {
  background-color: #fff;
}
.video-recorder .alert .buttons .btn.red,.video-recorder .dialog .buttons .btn.red {
  margin-top: 10px;
  background-color: rgba(255,0,51,.8);
  color: rgba(255,255,255,.8);
}
.video-recorder .alert .buttons .btn.red:hover,.video-recorder .dialog .buttons .btn.red:hover {
  background-color: #f03;
  color: #fff;
}
.video-recorder .alert .buttons .btn.grey,.video-recorder .dialog .buttons .btn.grey {
  margin-top: 10px;
  background-color: rgba(39,42,48,.6);
  color: rgba(255,255,255,.8);
}
.video-recorder .alert .buttons .btn.grey:hover,.video-recorder .dialog .buttons .btn.grey:hover {
  background-color: #272a30;
  color: #fff;
}
.video-recorder .alert .buttons .btn:last-child,.video-recorder .dialog .buttons .btn:last-child {
  margin-right: 0;
}
.video-recorder .alert,.video-recorder .disconnect-error {
  color: #fff;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.video-recorder .disconnect-error .buttons .btn-accept-cancel-req,.video-recorder .disconnect-error .buttons .btn-decline-cancel-req {
  width: 160px;
  height: 46px;
  display: block;
  border-radius: 22.5px;
  background-color: #fff;
  color: #272a30;
  transition: all .2s;
}
.video-recorder .disconnect-error .buttons .btn-accept-cancel-req:active,.video-recorder .disconnect-error .buttons .btn-decline-cancel-req:active {
  background-color: #19d481;
  color: #272a30;
}
.video-recorder.initializing .bottom-menu,.video-recorder.initializing .record-timer {
  opacity: 0;
}
.video-recorder.cancel-processing .bottom-menu,.video-recorder.processing .bottom-menu .btn-fs,.video-recorder.processing .btn-photo,.video-recorder.processing .btn-settings {
  display: none;
}
.video-recorder.alert-error .alert.error,.video-recorder.remove-video .remove-video-confirmation,.video-recorder.save-as-is .alert.save-as-is,.video-recorder.save-as-is-enc-err .alert.save-as-is-enc-err {
  visibility: visible;
  z-index: 5;
  opacity: 1;
}
.video-recorder.preview .video-player-container,.video-recorder.preview-photo .photo-preview {
  display: block;
}



